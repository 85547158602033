import { Container, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { LinkComp } from 'components/atoms';
import {
  BRAND_LOGO_SVG,
  LINK_APPLESTORE,
  LINK_DOWNLOAD_AUTO_DETECT,
  LINK_GOOGLEPLAY,
  LOGO_FOOTER_CONTACT,
  LOGO_FOOTER_LOCATION,
} from 'constants/Images';
import {
  ABOUT_US_URL,
  DBD_URL,
  GUIDE_URL,
  PATH_BUYMED_CAREER,
  PATH_BUYMED_LINE,
  PATH_TS_FACEBOOK,
  PATH_TS_LINKED,
  PATH_TS_TIKTOK,
} from 'constants/Paths';
import { PHONE_NUMBER_CONTACT, SUPPLY_CONTACT_EMAIL } from 'constants/data/contact';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect } from 'react';
import { ImageFallbackStatic } from 'utils/ImageFallback';
import { useStore } from 'zustand-lib/storeGlobal';
import styles from './styles.module.css';

const FooterThai = ({ isMobile = false }) => {
  const { t: translateCommon } = useTranslation('common');
  const policies = useStore((state) => state.policies);

  useEffect(() => {
    const hoverAreas = document.getElementsByClassName('general_info');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < hoverAreas.length; i++) {
      const hoverArea = hoverAreas[i];
      const { id: hoverAreaId } = hoverArea;
      const imageId = hoverAreaId?.split('_')[0] || '';
      if (imageId !== '') {
        const image = document.getElementById(imageId);
        hoverArea?.addEventListener('mouseenter', () => {
          image.src = `/static/images/footer/${imageId}_active.svg`;
        });

        hoverArea?.addEventListener('mouseleave', () => {
          image.src = `/static/images/footer/${imageId}_inactive.svg`;
        });
      }
    }
  }, []);
  return (
    <div id="app-footer" className={clsx(!isMobile ? styles.footer : styles.mobile_footer)}>
      <Container style={{ maxWidth: '1328px' }}>
        <Grid container justifyContent="space-between" direction={!isMobile ? 'row' : 'column'}>
          <Grid item xs={!isMobile ? 6 : 12}>
            <LinkComp href="/" padding="0px">
              <Image src={BRAND_LOGO_SVG} width="118" height="25" />
            </LinkComp>

            <div style={{ marginTop: '24px' }}>
              <Typography className={styles.companyName}>{translateCommon('footer.company_name')}</Typography>
            </div>
            <div style={{ marginTop: '4px' }}>
              <Typography className={styles.licenseNumber}>{translateCommon('footer.license_number')}</Typography>
            </div>
            <div style={{ marginTop: !isMobile ? '24px' : '12px', display: 'flex' }}>
              {!isMobile && (
                <div style={{ marginRight: '8px', width: '40px' }}>
                  <Image src={LOGO_FOOTER_LOCATION} width="40" height="40" />
                </div>
              )}
              <div style={{ flex: 1 }}>
                <Typography style={{ fontSize: '12px', fontFamily: 'nunsans' }}>{translateCommon('footer.company_address')}:</Typography>
                <Typography className={styles.info_content}>{translateCommon('footer.buymed_address')}</Typography>
              </div>
            </div>

            <div style={{ marginTop: !isMobile ? '24px' : '12px', display: 'flex' }}>
              {!isMobile && (
                <div style={{ marginRight: '8px' }}>
                  <Image src={LOGO_FOOTER_CONTACT} width="40" height="40" />
                </div>
              )}
              <div>
                <Typography style={{ fontSize: '12px', fontFamily: 'nunsans' }}>{translateCommon('footer.hotline')}:</Typography>
                <div style={{ display: 'flex', color: '#1B4BE5', fontSize: '14px' }}>
                  <a href={`tel:${PHONE_NUMBER_CONTACT.replace(/-/gi, '')}`} target="_blank" rel="noreferrer" className={styles.info_content}>
                    {PHONE_NUMBER_CONTACT}
                  </a>
                </div>
              </div>
              <div className={styles.verticalDashedLine} />
              <div>
                <Typography style={{ fontSize: '12px', fontFamily: 'nunsans' }}>{translateCommon('footer.supply_contact_mail')}:</Typography>
                <div style={{ display: 'flex', color: '#1B4BE5', fontSize: '14px' }}>
                  <a href={`mailto:${SUPPLY_CONTACT_EMAIL}`} target="_blank" rel="noreferrer" className={styles.info_content}>
                    {SUPPLY_CONTACT_EMAIL}
                  </a>
                </div>
              </div>
            </div>

            {!isMobile && (
              <div style={{ marginTop: '24px', display: 'flex' }}>
                <Image src="/static/images/footer/copyright.svg" width="16" height="16" />
                <Typography className={styles.copyRight}>{translateCommon('footer.copy_right')}</Typography>
              </div>
            )}
          </Grid>
          <Grid item container direction={!isMobile ? 'row' : 'column'} xs={!isMobile ? 6 : 12} justifyContent="flex-end">
            {policies?.length > 0 && (
              <Grid item xs={4} container direction="column" style={{ margin: !isMobile ? '0px' : '24px 0px' }}>
                <Typography className={styles.generalInfoTitle}>{translateCommon('footer.policies')}</Typography>
                {policies.map((policy, index) => (
                  <Grid item container alignItems="center" className={styles.policyItem}>
                    {policy?.documentUrl ? (
                      <LinkComp href={`/policy?code=${policy?.code || ''}`} key={policy?.code || index}>
                        <span className={styles.policyName}>{policy?.name || ''}</span>
                      </LinkComp>
                    ) : (
                      policy?.link && (
                        <LinkComp href={policy?.link} key={policy?.code || index} target="blank">
                          <span className={styles.policyName}>{policy?.name || ''}</span>
                        </LinkComp>
                      )
                    )}
                  </Grid>
                ))}
              </Grid>
            )}
            <Grid item xs={3} container direction="column" style={{ margin: !isMobile ? '0px' : '24px 0px' }}>
              <Typography className={styles.generalInfoTitle}>{translateCommon('footer.general_info')}</Typography>
              <Grid item container alignItems="center" className={`${styles.generalInfoItem} general_info`} id="career_link">
                <LinkComp
                  href={`${PATH_BUYMED_CAREER}/job-openings/?job__location_spec=cambodia-office-st-528-sangkat-boeung-kak-1-khan-toul-kork-phnom-penh`}
                >
                  <ImageFallbackStatic
                    src="/static/images/footer/career_inactive.svg"
                    layout="fixed"
                    height="20px"
                    width="20px"
                    alt="linked-career"
                    id="career"
                  />
                  <span style={{ marginLeft: '10px' }}>{translateCommon('footer.career')}</span>
                </LinkComp>
              </Grid>
              <Grid item container alignItems="center" className={`${styles.generalInfoItem} general_info`} id="aboutUs_link">
                <LinkComp href={ABOUT_US_URL}>
                  <ImageFallbackStatic
                    src="/static/images/footer/aboutUs_inactive.svg"
                    layout="fixed"
                    height="20px"
                    width="20px"
                    alt="linked-connect"
                    id="aboutUs"
                  />
                  <span style={{ marginLeft: '10px' }}>{translateCommon('footer.about_us')}</span>
                </LinkComp>
              </Grid>
              <Grid item container alignItems="center" className={`${styles.generalInfoItem} general_info`} id="information_link">
                <LinkComp href={GUIDE_URL}>
                  <ImageFallbackStatic
                    src="/static/images/footer/information_inactive.svg"
                    layout="fixed"
                    height="20px"
                    width="20px"
                    alt="linked-connect"
                    id="information"
                  />
                  <span style={{ marginLeft: '10px' }}>{translateCommon('footer.user_guide')}</span>
                </LinkComp>
              </Grid>
              {!isMobile && (
                <Grid item container direction="column" alignItems="flex-start" style={{ marginTop: '24px' }}>
                  <Typography className={styles.generalInfoTitle}>{translateCommon('footer.certified_by')}</Typography>
                  <LinkComp href={DBD_URL} target>
                    <ImageFallbackStatic src="/static/images/footer/dbd.svg" layout="fixed" height="40px" width="102px" alt="dbd-registered" />
                  </LinkComp>
                </Grid>
              )}
            </Grid>
            <Grid
              item
              container
              direction={!isMobile ? 'row' : 'row-reverse'}
              xs={!isMobile ? 5 : 12}
              justifyContent={!isMobile ? 'space-between' : 'flex-end'}
            >
              <Grid item xs={!isMobile ? 12 : 5}>
                <Typography className={styles.generalInfoTitle}>{translateCommon('footer.connect_with_us')}</Typography>
                <Grid item container alignItems="center" justifyContent="flex-start">
                  <Grid item className={styles.connectIcon}>
                    <Link href={PATH_TS_FACEBOOK} passHref>
                      <a href={PATH_TS_FACEBOOK} target="_blank" rel="noreferrer" className={styles.footer_icon}>
                        <Image src="/static/images/footer/fb.svg" height="36" width="36" alt="facebook-connect" />
                      </a>
                    </Link>
                  </Grid>
                  <Grid item className={styles.connectIcon}>
                    <Link href={PATH_TS_LINKED} passHref>
                      <a href={PATH_TS_LINKED} target="_blank" rel="noreferrer" className={styles.footer_icon}>
                        <Image src="/static/images/footer/linkedin.svg" height="36" width="36" alt="linkedin-connect" />
                      </a>
                    </Link>
                  </Grid>
                  <Grid item className={styles.connectIcon}>
                    <Link href={PATH_BUYMED_LINE} passHref>
                      <a href={PATH_BUYMED_LINE} target="_blank" rel="noreferrer" className={styles.footer_icon}>
                        <Image src="/static/images/footer/line-2.svg" height="36" width="36" alt="line-connect" />
                      </a>
                    </Link>
                  </Grid>
                  <Grid item className={styles.connectIcon}>
                    <Link href={PATH_TS_TIKTOK} passHref>
                      <a href={PATH_TS_TIKTOK} target="_blank" rel="noreferrer" className={styles.footer_icon}>
                        <Image src="/static/images/footer/tiktok.svg" height="36" width="36" alt="tiktok-connect" />
                      </a>
                    </Link>
                  </Grid>
                </Grid>
                {isMobile && (
                  <Grid item container direction="column" alignItems="flex-start" style={{ marginTop: '12px' }}>
                    <Typography className={styles.generalInfoTitle}>{translateCommon('footer.certified_by')}</Typography>
                    <LinkComp href={DBD_URL} target>
                      <ImageFallbackStatic src="/static/images/footer/dbd.svg" layout="fixed" height="40px" width="102px" alt="dbd-registered" />
                    </LinkComp>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={!isMobile ? 12 : 7}>
                <Typography className={styles.generalInfoTitle}>{translateCommon('footer.download_app')}</Typography>
                <div style={{ display: 'flex', paddingTop: 12 }}>
                  {!isMobile && (
                    <div style={{ marginRight: 12 }}>
                      <a href={LINK_DOWNLOAD_AUTO_DETECT} target="_blank" rel="noreferrer">
                        <Image src="/static/images/footer/qr-2.png" width="88" height="88" />
                      </a>
                    </div>
                  )}
                  <div>
                    <div>
                      <a href={LINK_APPLESTORE} target="_blank" rel="noreferrer">
                        <Image src="/static/images/footer/appstore-3.svg" width="107" height="37" />
                      </a>
                    </div>
                    <div style={{ marginTop: 10 }}>
                      <a href={LINK_GOOGLEPLAY} target="_blank" rel="noreferrer">
                        <Image src="/static/images/footer/ggplay.svg" width="107" height="35" />
                      </a>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default FooterThai;
